<template>
  <v-card>
    <v-card-title class="px-2">
      {{ $t('course_history') }} <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="exportLoading"
        :disabled="exportLoading"
        @click="exportExcel"
      >
        {{ $t('excel') }}
      </v-btn>
    </v-card-title>
    <Search2
      @onSendDate="searchLog"
    />
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :server-items-length="totalDataTableList"
      :footer-props="footer"
      :loading="loading"
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.id`]="{ index }">
        {{ segmentId + index + 1 }}
      </template>
      <template v-slot:[`item.log_time`]="{ item }">
        <ConversDateTime :datetime="item.log_time" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import Search2 from './Search2.vue'
import { i18n } from '@/plugins/i18n'
import logs from '@/api/logs/logs'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'

export default {
  components: {
    Search2,
    ConversDateTime,
  },
  setup() {
    const XLSX = require('xlsx')
    const loading = ref(false)
    const options = ref({})
    const payloadData = ref({})
    const dataTableList = ref([])
    const totalDataTableList = ref(0)
    const segmentId = ref(0)
    const columns = ref([
      {
        text: '#',
        align: 'start',
        value: 'id',
        width: 50,
      },

      {
        text: '',
        value: 'log_text',
      },
      {
        text: `${i18n.t('code')}`,
        value: 'course_id',
        width: 150,
      },
      {
        text: `${i18n.t('course_name')}`,
        value: 'course_name',
        width: 150,
      },
      {
        text: `${i18n.t('category')}`,
        value: 'coursegroup_name',
        width: 150,
      },
      {
        text: `${i18n.t('course_price')}`,
        value: 'course_price',
        width: 150,
        align: 'end',
      },
      {
        text: `${i18n.t('doctor_fee')}`,
        value: 'course_costdoctor',
        width: 150,
        align: 'end',
      },
      {
        text: `${i18n.t('staff_costs')}`,
        value: 'course_costuser',
        width: 150,
        align: 'end',
      },
      {
        text: `${i18n.t('times_courses')}`,
        value: 'course_amount',
        width: 150,
        align: '  end',
      },
      {
        text: `${i18n.t('date_range')}`,
        value: 'course_expday',

        width: 150,
      },
      {
        text: `${i18n.t('by')}`,
        value: 'user_fullname',
        width: 150,
      },
      {
        text: `${i18n.t('status')}`,
        value: 'course_status_name',
        width: 150,
      },
      {
        text: `${i18n.t('date')}`,
        value: 'log_time',
        width: 180,
      },
    ])
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const exportLoading = ref(false)
    const searchLog = payload => {
      payloadData.value = payload
      loading.value = true
      logs.logcourse({
        ...payload,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      }).then(res => {
        const {
          data, count, segment,
        } = res
        dataTableList.value = data
        totalDataTableList.value = count
        segmentId.value = segment
        loading.value = false
      })
    }

    const exportExcel = () => {
      exportLoading.value = true
      logs.logcourse({
        ...payloadData.value,
        active_page: 1,
        per_page: '0',
      }).then(res => {
        const dataExport = res.data.map((item, i) => {
          delete item.log_text

          return { ...item, number: i + 1 }
        })
        const fileName = `${i18n.t('course_history')}.xlsx`

        //* เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx *//
        /*  รายงานวิเคราะห์รายการตรวจ */
        const Heading = [
          [`${i18n.t('course_history')}`],
          [
            '#',
            `${i18n.t('code')}`,
            `${i18n.t('list')}`,
            `${i18n.t('course_name')}`,
            `${i18n.t('category')}`,
            `${i18n.t('course_price')}`,
            `${i18n.t('doctor_fee')}`,
            `${i18n.t('staff_costs')}`,
            `${i18n.t('times_courses')}`,
            `${i18n.t('date_range')}`,
            `${i18n.t('by')}`,
            `${i18n.t('status')}`,
            `${i18n.t('date')}`,
          ],
        ]
        const ws = XLSX.utils.aoa_to_sheet(Heading)
        XLSX.utils.sheet_add_json(ws, dataExport, {
          header: [
            'number',
            'course_id',
            'log_text',
            'course_name',
            'coursegroup_name',
            'course_price',
            'course_costdoctor',
            'course_costuser',
            'course_amount',
            'course_expday',
            'user_fullname',
            'course_status_name',
            'log_time',
          ],
          skipHeader: true,
          origin: -1,
        })
        const wb = XLSX.utils.book_new()
        const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }]
        const wscols = [
          { wch: 8 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ]
        ws['!merges'] = merge
        ws['!cols'] = wscols
        XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('course_history')}`)

        /* พิมร์ files */
        XLSX.writeFile(wb, fileName)
        setTimeout(() => {
          exportLoading.value = false
        }, 1000)
      })
    }

    watch([options], () => {
      searchLog(payloadData.value)
    })

    return {
      XLSX,
      loading,
      options,
      dataTableList,
      totalDataTableList,
      columns,
      segmentId,
      searchLog,
      footer,
      exportLoading,
      exportExcel,

    }
  },
}
</script>
